import React from "react";
import { Link, StaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import CallButtonV2 from "./CallButtonV2";
import LinkButtonV2 from "./LinkButtonV2";
import funeral from "../../assets/img/funeral.svg";
import prepaid from "../../assets/img/prepaid.svg";
import will from "../../assets/img/will.svg";
import care from "../../assets/img/care.svg";

const BlogPreviewInner = styled.div`
  .wrap {
    max-width: 1150px;
    margin: 70px auto;
    width: calc(100% - 100px);

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 700px;
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;
      text-align: center;
      line-height: 50px;
      width: calc(100% - 40px);
      margin: 0 auto;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
        width: calc(100%);
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    .desc {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      text-align: center;
      line-height: 28px;
      max-width: 900px;
      margin: 30px auto;
      width: calc(100% - 40px);
      margin-bottom: 50px;

      @media (max-width: 1250px) {
        max-width: 700px;
      }

      @media (max-width: 899px) {
        width: calc(100%);
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 25px;
        margin-bottom: 40px;
      }
    }

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 899px) {
        display: block;
      }

      li {
        background: #FFFFFF;
        box-shadow: 0 6px 20px 0 rgba(0,0,0,0.10);
        border-radius: 10px;
        overflow: hidden;
        width: calc(100%/3 - 30px);
        align-self: stretch;
        position: relative;

        @media (max-width: 1100px) {
          width: calc(100%/3 - 10px);
        }

        @media (max-width: 899px) {
          width: 100%;
          margin-bottom: 40px;
        }

        .obrazek {
          width: 100% !important;
          margin-bottom: 15px;
        }

        .inner {
          width: calc(100% - 50px);
          margin: 0 auto;
          padding-bottom: calc(25px + 61px);

          h3 {
            font-family: Visuelt-Medium;
            font-size: 22px;
            color: #243A58;
            margin-top: 10px;
            margin-bottom: 10px;
            
            a {
              color: #243A58;
              font-family: Visuelt-Medium;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .time {
            color: #333;
            font-family: Visuelt-Medium;
            display: block;
            font-size: 16px;
            display: block;
          }

          .category {
            color: #333;
            font-family: Visuelt-Medium;
            display: block;
            font-size: 16px;
            display: block;
          }

          p {
            color: #4E4E4E;
            font-family: Visuelt-Regular;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
            margin-bottom: 20px;

            a {
              color: #4E4E4E;
            }
          }

          .linkButton {
            position: absolute;
            bottom: 25px;
            min-width: 0 !important;

            @media (max-width: 360px) {
              width: calc(100% - 90px);
            }
          }
        }
      }
    }

    .linkButton {
      display: block;
      margin: 0 auto;
      margin-top: 40px;
      min-width: 220px;

      @media (max-width: 360px) {
        min-width: 0px;
        width: calc(100% - 40px);
      }
    }

    .botButton {
      .linkButton {
        a {
          display: block;
          margin: 0 auto;
          min-width: 220px;

          @media (max-width: 360px) {
            min-width: 0px;
            width: calc(100% - 40px);
          }
        }
        
      }
    }
    
  }
`;


const BlogPreview = ({wills, prepaid}) => {
  return (
    <BlogPreviewInner className="blogPreview">
      <div className="wrap">
        <h2>Nahlédněte do naší poradny plné užitečných informací</h2>
        <p className="desc">Pokud jste přetíženi nebo nevíte jak dál, obraťte se na nás. Pomůžeme vám se ve všem zorientovat. Na spoustu otázek jsme již odpověděli v některém z našich článků.</p>
        
        <StaticQuery
          query={graphql`
            query {
              clanek1: file(relativePath: { eq: "clanek1.jpg" }) {
                childImageSharp {
                  fixed(width: 400, height: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }

              clanek2: file(relativePath: { eq: "clanek2.jpg" }) {
                childImageSharp {
                  fixed(width: 400, height: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }

              clanek3: file(relativePath: { eq: "clanek3.jpg" }) {
                childImageSharp {
                  fixed(width: 400, height: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }

              vydedeni: file(relativePath: { eq: "vydedeni.jpeg" }) {
                childImageSharp {
                  fixed(width: 400, height: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }

              zavet: file(relativePath: { eq: "zavet.jpeg" }) {
                childImageSharp {
                  fixed(width: 400, height: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }

              prepaidPic: file(relativePath: { eq: "prepaidPic.jpeg" }) {
                childImageSharp {
                  fixed(width: 400, height: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <ul>
              {wills ? <>
                <li>
                  <Img className="obrazek" alt="Závěť a vše, co o jejím psaní potřebujete vědět" fixed={data.zavet.childImageSharp.fixed} />
                  <div className="inner">
                    <span className="time">15 minut čtení</span>
                    <h3><Link to="/poradna/jak-napsat-zavet/">Závěť a vše, co o jejím psaní potřebujete vědět</Link></h3>
                    <span className="category">Právo a dědictví</span>
                    <p>Poslední vůli by měl mít každý, kdo nechce nechat nic náhodě. Jak ale začít? Kdy stačí napsat závěť doma a kdy je potřeba jít k notáři? Co všechno v ní musí být? A co se stane, když někdo zemře bez závěti? Na všechny tyhle otázky a na spoustu dalších najdete odpovědi v našem článku.</p>
                    <LinkButtonV2 to={"/poradna/jak-napsat-zavet/"} text="Otevřít článek" arrow light />
                  </div>
                </li>
                <li>
                  <Img className="obrazek" alt="Vydědění potomka podle dědického práva" fixed={data.vydedeni.childImageSharp.fixed} />
                  <div className="inner">
                    <span className="time">5 minut čtení</span>
                    <h3><Link to="/poradna/vydedeni-podle-dedickeho-prava/">Vydědění potomka podle dědického práva</Link></h3>
                    <span className="category">Právo a dědictví</span>
                    <p>V souvislosti s psaním závěti či s dědickým právem často slýcháme termín “vydědění”. Co to ale přesně znamená? Koho můžeme ve své závěti bez problémů opomenout a koho musíme oficiálně vydědit? A co dělat, když naopak chce někdo neprávem vydědit nás?</p>
                    <LinkButtonV2 to={"/poradna/vydedeni-podle-dedickeho-prava/"} text="Otevřít článek" arrow light />
                  </div>
                </li>
                <li>
                  <Img className="obrazek" alt="Průvodce dědickým řízením od A do Z" fixed={data.clanek1.childImageSharp.fixed} />
                  <div className="inner">
                    <span className="time">8 minut čtení</span>
                    <h3><Link to="/poradna/dedicke-rizeni/">Průvodce dědickým řízením od A do Z</Link></h3>
                    <span className="category">Právo a dědictví</span>
                    <p>Pozůstalostní řízení je neodmyslitelnou součástí procesu, který následuje po úmrtí blízké osoby. Někdy se může nepříjemně protahovat a zkomplikovat rodinné vztahy, je tedy dobré vědět, co vás čeká, abyste předešli nemilým překvapením.</p>
                    <LinkButtonV2 to={"/poradna/dedicke-rizeni/"} text="Otevřít článek" arrow light />
                  </div>
                </li>
              </> : <>

                {prepaid ? <>

                  <li>
                    <Img className="obrazek" alt="Předplacený pohřeb: Na co si dát pozor?" fixed={data.prepaidPic.childImageSharp.fixed} />
                    <div className="inner">
                      <span className="time">7 minut čtení</span>
                      <h3><Link to="/poradna/predplacene-pohrby/">Předplacený pohřeb: Na co si dát pozor?</Link></h3>
                      <span className="category">Úmrtí a pohřeb</span>
                      <p>Češi si dopředu plánují dovolenou i vlastní pohřeb. Touha nezatížit po smrti rodinu zařizováním obřadu i finančními náklady je stále populárnější. Předplacené pohřby nabízejí specializované služby či pohřební ústavy. Jednou z možností je také dopředu si poslední rozloučení pojistit.</p>
                      <LinkButtonV2 to={"/poradna/predplacene-pohrby/"} text="Otevřít článek" arrow light />
                    </div>
                  </li>

                  <li>
                    <Img className="obrazek" alt="Závěť a vše, co o jejím psaní potřebujete vědět" fixed={data.zavet.childImageSharp.fixed} />
                    <div className="inner">
                      <span className="time">15 minut čtení</span>
                      <h3><Link to="/poradna/jak-napsat-zavet/">Závěť a vše, co o jejím psaní potřebujete vědět</Link></h3>
                      <span className="category">Právo a dědictví</span>
                      <p>Poslední vůli by měl mít každý, kdo nechce nechat nic náhodě. Jak ale začít? Kdy stačí napsat závěť doma a kdy je potřeba jít k notáři? Co všechno v ní musí být? A co se stane, když někdo zemře bez závěti? Na všechny tyhle otázky a na spoustu dalších najdete odpovědi v našem článku.</p>
                      <LinkButtonV2 to={"/poradna/jak-napsat-zavet/"} text="Otevřít článek" arrow light />
                    </div>
                  </li>
                  
                  <li>
                    <Img className="obrazek" alt="Vydědění potomka podle dědického práva" fixed={data.vydedeni.childImageSharp.fixed} />
                    <div className="inner">
                      <span className="time">5 minut čtení</span>
                      <h3><Link to="/poradna/vydedeni-podle-dedickeho-prava/">Vydědění potomka podle dědického práva</Link></h3>
                      <span className="category">Právo a dědictví</span>
                      <p>V souvislosti s psaním závěti či s dědickým právem často slýcháme termín “vydědění”. Co to ale přesně znamená? Koho můžeme ve své závěti bez problémů opomenout a koho musíme oficiálně vydědit? A co dělat, když naopak chce někdo neprávem vydědit nás?</p>
                      <LinkButtonV2 to={"/poradna/vydedeni-podle-dedickeho-prava/"} text="Otevřít článek" arrow light />
                    </div>
                  </li>
              
                </>: <>
                  <li>
                    <Img className="obrazek" alt="Průvodce dědickým řízením od A do Z" fixed={data.clanek1.childImageSharp.fixed} />
                    <div className="inner">
                      <span className="time">8 minut čtení</span>
                      <h3><Link to="/poradna/dedicke-rizeni/">Průvodce dědickým řízením od A do Z</Link></h3>
                      <span className="category">Právo a dědictví</span>
                      <p>Pozůstalostní řízení je neodmyslitelnou součástí procesu, který následuje po úmrtí blízké osoby. Někdy se může nepříjemně protahovat a zkomplikovat rodinné vztahy, je tedy dobré vědět, co vás čeká, abyste předešli nemilým překvapením.</p>
                      <LinkButtonV2 to={"/poradna/dedicke-rizeni/"} text="Otevřít článek" arrow light />
                    </div>
                  </li>

                  <li>
                    <Img className="obrazek" alt="" fixed={data.clanek2.childImageSharp.fixed} />
                    <div className="inner">
                      <span className="time">4 minuty čtení</span>
                      <h3><Link to="/poradna/prehled-cen-pohrbu/">Kolik stojí pohřeb aneb velký přehled aktuálních cen</Link></h3>
                      <span className="category">Úmrtí a pohřeb</span>
                      <p>Organizace pohřbu bývá velmi náročná nejen psychicky, ale i finančně. Pomůžeme vám udělat si přehled o aktuálních cenách. Co ceny ovlivňuje, co zahrnují a za co se naopak platí zvlášť. Kolik stojí <Link to="/kremace-bez-obradu/">kremace bez obřadu</Link> nebo <Link to="/netradicni-pohreb/">netradiční pohřeb</Link>?</p>
                      <LinkButtonV2 to={"/poradna/prehled-cen-pohrbu/"} text="Otevřít článek" arrow light />
                    </div>
                  </li>

                  <li>
                    <Img className="obrazek" alt="" fixed={data.clanek3.childImageSharp.fixed} />
                    <div className="inner">
                      <span className="time">7 minut čtení</span>
                      <h3><Link to="/poradna/smutecni-rec-rady-tipy/">Jak napsat smuteční řeč</Link></h3>
                      <span className="category">Úmrtí a pohřeb</span>
                      <p>Během plánování <Link to="/pohreb-s-obradem/">smutečního obřadu</Link> je třeba se rozhodnout o spoustě věcí. Pokud je to možné, řídíme se vždy přáními zesnulého. Pokud ale nevíme, jaká byla jeho představa, je rozhodování na nás a nejbližších, kteří nám pomáhají s organizací pohřbu.</p>
                      <LinkButtonV2 to={"/poradna/smutecni-rec-rady-tipy/"} text="Otevřít článek" arrow light />
                    </div>
                  </li>
                </>}
              </>}
              
            </ul>
          )}
        />

        <div className="botButton">
          <LinkButtonV2 text="Navštívit poradnu" to="/poradna/" />
        </div>

      </div>
    </BlogPreviewInner>
  );
}

export default BlogPreview;


